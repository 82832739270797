import jQuery from 'jquery';

export default function headerMenu() {
  const $ = jQuery.noConflict();

  const header = document.querySelector('.header');
  const openMenuTrigger = document.querySelector('.menu-opener');
  const dropdownMenuTrigger = document.querySelectorAll('.menu-item-has-children > a');

  dropdownMenuTrigger.forEach((item) => {
    const subArrowButton = document.createElement('button');
    subArrowButton.className = 'sub-arrow';
    subArrowButton.setAttribute('type', 'button');

    item.appendChild(subArrowButton);

    subArrowButton.addEventListener('click', (e) => {
      e.preventDefault();
      const parent = subArrowButton.closest('.menu-item-has-children');
      const submenu = parent.querySelector('.sub-menu__wrapp');
      $(submenu).slideToggle();
    });
  });

  openMenuTrigger.addEventListener('click', () => {
    document.body.classList.toggle('menu-active');
  });

  dropdownMenuTrigger.forEach((dropdownTrigger) => {
    const dropdown = dropdownTrigger.closest('.menu-item-has-children').querySelector('.sub-menu__wrapp');

    dropdownTrigger.addEventListener('mouseenter', function () {
      header.classList.add('bg-white');
    });

    dropdown.addEventListener('mouseenter', function () {
      header.classList.add('bg-white');
    });

    dropdownTrigger.addEventListener('mouseleave', function () {
      header.classList.remove('bg-white');
    });

    dropdown.addEventListener('mouseleave', function () {
      header.classList.remove('bg-white');
    });
  });
}
