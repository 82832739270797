import AOS from 'aos';
import jQuery from 'jquery';

export default function initAos() {
  const $ = jQuery.noConflict();

  // Documentation here: https://github.com/michalsnik/aos/tree/v2
  const CONFIG = {
    easing: 'ease-out-quart',
    offset: 0,
    duration: 1000,
    once: true,
  };

  let delayCounter = 0;
  const DELAY_STEP = 200; // can be any number (milliseconds)

  const $aosBlocks = $('[data-aos]');

  if (!$aosBlocks) return;

  const inViewportBlocks = $aosBlocks.filter((_, el) => isElemVisible(el));

  if (inViewportBlocks.length) {
    // Apply sequenced animation delay for all blocks in viewport on load
    inViewportBlocks.each((_, el) => {
      const delayInSeconds = delayCounter ? delayCounter / 1000 : 0;

      $(el).css('transition-delay', `${delayInSeconds}s`);

      delayCounter += DELAY_STEP;
    });
  }

  AOS.init(CONFIG);

  function isElemVisible(el) {
    const { top, bottom } = el.getBoundingClientRect();
    const vHeight = window.innerHeight || document.documentElement.clientHeight;

    return (top > 0 || bottom > 0) && top < vHeight;
  }
}
