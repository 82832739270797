import isElementExist from './helpers/isElementExist';
import initHeaderOffset from './modules/headerOffset';
import stickyHeader from './modules/stickyHeader';
import quotesSlider from './modules/quotesSlider';
import headerMenu from './modules/headerMenu';
import accordion from './modules/accordion';
import starsRating from './modules/starsRating';
import togglePassword from './modules/togglePassword';
import initAos from './modules/initAos';
import popupCta from './modules/popupCta';
import popupLogin from './modules/popupLogin';
import initJcf from './modules/initJcf';
import initMenuOffset from './modules/menuOffset';

window.addEventListener('DOMContentLoaded', () => {
  initAos();
  initJcf();
  isElementExist('.header', initHeaderOffset);
  isElementExist('.header', initMenuOffset);
  isElementExist('.header', stickyHeader);
  isElementExist('.header', headerMenu);
  isElementExist('.accordion', () => {
    accordion({
      containerClass: '.accordion',
      elementClass: 'accordion_item',
      triggerClass: 'accordion_button',
      panelClass: 'accordion_dropdown',
      activeClass: '_active',
    });
  });
  isElementExist('.modern-accordion', () => {
    accordion({
      containerClass: '.modern-accordion',
      elementClass: 'accordion_item',
      triggerClass: 'accordion_button',
      panelClass: 'accordion_dropdown',
      activeClass: 'accordion_item_active',
    });
  });
  isElementExist('.quotes-slider', quotesSlider);
  isElementExist('.stars-rating', starsRating);
  isElementExist('.input-password', togglePassword);
  isElementExist('.popup-cta-trigger', popupCta);
  isElementExist('.popup-login-trigger', popupLogin);
});
